const firebaseConfig = {
    apiKey: "AIzaSyDucM9BGb7iADwiujg-ERJZyXW6fLwuD3A",
    authDomain: "evolution-map-2eaf7.firebaseapp.com",
    projectId: "evolution-map-2eaf7",
    storageBucket: "evolution-map-2eaf7.appspot.com",
    messagingSenderId: "253406831053",
    databaseURL: "https://evolution-map-2eaf7.firebaseio.com",
    appId: "1:253406831053:web:de0e998b477492e8296484"
};
export default firebaseConfig;

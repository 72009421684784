import React from 'react';
import './App.css';
import CheckToken from './model/checkToken';
import { RouteComponentProps } from 'react-router';

type urlProps = {} & RouteComponentProps<{token: string, type: string}>;

const App: React.FC<urlProps> = (props) => {
  const [initialising, setInitialising] = React.useState<boolean>(true);
  const [status, setStatus] = React.useState<string>("");
  const [code, setCode] = React.useState<string>("");
  const [msg, setMsg] = React.useState<string>("確認中...");
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const type = params.get('type');
  const check = async () => {
    let device="pc";
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0) {
        device="ios";
    } else if (ua.indexOf('ipad') > 0) { // iOS12 まで
        device="ios";
    } else if (ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document) { // iOS13 以降
        device="ios";
    }  else if (ua.indexOf('android') > 0) {
        device="android";
    }
    let model = new CheckToken();
      if(type == "sg") { //for AppShareGroup(default)
        model.check(token)
          .then((value) => {
            setInitialising(false)
            setStatus(value.status)
            code2ErrorMsg(value.code);
            if(value.status == "SUCCESS") {
              if(device =="ios") {
                //window.location.href = value.response.shortLink.replace("https://","https://preview.page.link/");
                window.location.href = value.response.shortLink;
              } else if(device=="android") {
                window.location.href = value.response.shortLink;
              } else {
                setMsg("evolution mapをインストールしている端末よりアクセスしてください。");
              }
            } else {
              code2ErrorMsg(value.code);
            }
          }).catch((error) => {
            setInitialising(false)
          });
      } else { //for AppShareGroup(default)
        model.check(token)
          .then((value) => {
            setInitialising(false)
            setStatus(value.status)
            if(value.status == "SUCCESS") {
              if(device =="ios") {
                window.location.href = value.response.shortLink.replace("https://","https://preview.page.link/");
              } else if(device =="android") {
                window.location.href = value.response.shortLink;
              } else {
                setMsg("evolution mapをインストールしている端末よりアクセスしてください。");
              }
            } else {
              code2ErrorMsg(value.code);
            }
          }).catch((error) => {
            setInitialising(false)
          });
      }

    };
  function code2ErrorMsg(code: any) {
    if(code) {
      if(code=="invalidToken") {
        setMsg("このURLは無効です。");
      } else if(code=="usedToken") {
        setMsg("このURLは既に利用されております。");
      } else if(code=="LIMIT_OVER") {
        setMsg("このURLは有効期限が切れているため、利用できません。");
      } else if(code=="NOT_EXIST") {
        setMsg("このURLは無効です。");
      } else if(code=="STILL_LOGGEDIN") {
        setMsg("このURLは無効です。");
      }
    } 
  };

  if (initialising) {
    check();
  }
  return <MsgBox children={msg} />;
};

class MsgBox extends React.Component {
  render() {
    return <div className="msgBox">{this.props.children}</div>;
  }
}


export default App;

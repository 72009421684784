import firebase from 'firebase/app';
import firebaseConfig from './FirebaseConfig';
import "firebase/firestore";
import 'firebase/functions';

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

const REGION_TOKYO = "asia-northeast1";
export const functions = firebase.app().functions(REGION_TOKYO)

export default firebase;
